@import '../../styles/includes';

.Breadcrumbs {
    overflow: hidden;

    &__List {
        display: flex;
        flex-direction: row;
        overflow-y: auto;

        @include media(m) {
            overflow-y: hidden;
        }
    }

    &__Item {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        padding: 8px 0 8px 4px;

        &:first-of-type {
            padding: 8px 0px 8px 0;
        }
    }

    &__Link {
        display: flex;
        flex-direction: row;
        font-size: 1.0rem;
        color: $colorBlack;
        transition: color 0.15s ease, text-decoration-color 0.15s ease;
        will-change: color, text-decoration-color;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 3px;
        text-decoration-color: transparent;

        @include media(m) {
            font-size: 1.2rem;
        }

        @media (hover: hover) {
            &:hover {
                color: $colorRed50;
                text-decoration-color: $colorRed50;
            }
        }

        &--Active {
            pointer-events: none;
            color: $colorGray70;
        }
    }

    &__Icon {
        display: block;
        margin: 0 0 0 4px;
    }

    &__Container {
        @extend %container;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100vw;
            height: 100%;
            background-color: $colorGray10;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center bottom;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
    }
}