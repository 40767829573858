@import 'styles/includes';

.ImageLightBox {
    $root: &;
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 4;
    will-change: filter;
    transition: filter 0.5s ease;

    &:hover {
        filter: brightness(1.16);
    }

    &__IconContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__LightBox {
        --yarl__color_backdrop: rgba(0, 0, 0, 0.6);
    }
}