@import '../../styles/includes';
.ProductPage {
    &__Breadcrumbs {
        padding-top: 24px;

        @include media(m) {
            padding-top: 32px;
        }

        @include media(ml) {
            nav {
                display: none;
            }
        }
    }
}
