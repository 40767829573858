@import 'styles/includes';

.TabLinks {
    position: relative;
    padding-bottom: 100px;
    z-index: 1;

    &__Container {
        @extend %container;
    }

    &__TabWrapper {
        display: flex;
        border-bottom: 1px solid $colorGray30;
        padding-bottom: 24px;
        overflow-x: scroll;
        white-space: nowrap;
        overflow-y: hidden;
        gap: 32px;
        margin-right: -16px;
        scrollbar-width: none;

        @include media(m) {
            margin-right: 0;
            overflow-x: inherit;
            white-space: normal;
            gap: 64px;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &--SpaceBetween {
            @include media(m) {
                gap: 12px;
                justify-content: space-between;
            }
        }
    }

    &__Tab {
        font-family: $fontLight;
        font-size: 20px;
        cursor: pointer;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            bottom: -24px;
            background-color: $colorRed50;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.3s ease;
        }

        &:focus {
            &::after {
                transform: scaleX(1);
            }
        }

        &:hover {
            cursor: pointer;
            &::after {
                transform: scaleX(1);
                transform-origin: left;
            }
        }

        @include media(m) {
            font-size: 24px;
        }

        &:last-child {
            margin-right: 16px;

            @include media(m) {
                margin-right: 0;
            }
        }

        &--Active {
            position: relative;
            z-index: 12312313;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                bottom: -24px;
                background-color: $colorRed50;
                transform: scaleX(1);
            }
        }
    }
}
