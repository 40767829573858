@import '../../styles/variables';
@import '../../styles/includes';

.ProductHero {
    $root: &;
    padding: 56px 0;

    &__Row {
        @include media(m) {
            display: flex;
            margin-bottom: 68px;
        }
    }

    &__Container {
        @extend %container;
    }

    &__Col {
        padding-right: 0;
        width: 100%;

        @include media(m) {
            padding-right: 96px;
        }

        &:last-child {
            padding-right: 0;
        }

        &__Single {
            margin: auto 0;
        }
    }

    &__ImageWrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 40px;

        @include media(m) {
            height: 50px;
        }

        img {
            object-position: left center;
        }
    }

    &__TitleWrapper {}

    &__Title {
        @extend %h2;
        font-family: $fontLight;
    }

    &__TabContent {
        width: 100%;
    }

    &__TabImageWrapper {
        padding-bottom: calc((200 / 666) * 100%);
        width: 100%;
        position: relative;
        margin-top: 24px;

        #{$root}__Col__Single & {
            margin-top: 0;
        }
    }

    &__Text {
        margin-top: 28px;
        font-family: $fontLight;
        max-width: 500px;
        font-size: 18px;
        line-height: 135%;

        @include media(m) {
            font-size: 20px;
        }
    }

    &__ModelText {
        font-family: $fontRegular;
        font-size: 14px;
        margin-bottom: 16px;
        margin-top: 64px;

        @include media(m) {
            margin-top: 12px;
        }
    }

    &__TabContainer {
        display: flex;
        border-bottom: 1px solid $colorGray30;
        padding-bottom: 8px;
        overflow-x: scroll;
        white-space: nowrap;
        overflow-y: hidden;
        gap: 32px;
        margin-right: -16px;

        //Hide scrollbar
        -ms-overflow-style: none; //Internet Explorer, Edge

        scrollbar-width: none; //Firefox

        &::-webkit-scrollbar {
            display: none; // Chrome, Safari and Opera
        }

        @include media(m) {
            gap: 12px;
            margin-right: 0;
            overflow-x: inherit;
            white-space: normal;
            justify-content: space-between;
        }

        #{$root}__Col__Single & {
            border-bottom: none;
        }
    }

    &__Tab {
        font-family: $fontLight;
        background-color: transparent;
        padding: 0;
        color: $colorBlack;
        margin: 0;
        border: 0;
        font-size: 1.6rem;
        cursor: pointer;
        position: relative;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            bottom: -8px;
            background-color: $colorRed50;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.3s ease;
            will-change: transition;
        }

        &--Active {
            &::after {
                transform: scaleX(1);
            }
        }

        @media (hover: hover) {
            &:hover {
                cursor: pointer;

                &::after {
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }
        }

        &--Single {
            &:after {
                display: none;
            }
        }

        #{$root}__Col__Single & {
            justify-content: flex-start;

            &::after {
                border-bottom: none;
            }
        }
    }

    &__InfoText {
        margin-top: 16px;
        font-family: $fontRegular;
        font-size: 14px;
    }

    &__RowBottom {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include media(m) {
            flex-direction: row;
            gap: 24px;
        }

        .ProductHero__ImageWrapper {
            padding-bottom: calc((360 / 616) * 100%);
            width: 100%;
            position: relative;

            &:first-child {
                margin-top: 32px;
            }

            @include media(m) {
                padding-bottom: calc((160 / 616) * 100%);
                margin-top: 24px;

                &:first-child {
                    margin-top: 24px;
                }
            }

            img {
                object-position: top left !important;
            }
        }
    }
}